<template>
    <v-container class="listSegnalazioni">
        <v-data-iterator
            :items="segnalazioni"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            :sort-by="sortBy.toLowerCase()"
            :sort-desc="sortDesc"
            no-data-text="Ancora Nessuna Segnalazione"
            no-results-text="Nessuna Segnalazione"
            hide-default-footer
        >
            <template v-slot:header>
                <v-toolbar
                    color="secondary"
                    class="mb-5"
                >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        hide-details
                        background-color="white"
                        label="Cerca"
                        class="blackTextWriting"
                    >
                        <template v-slot:prepend-inner>
                            <v-icon small>fas fa-search</v-icon>
                        </template>
                    </v-text-field>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        v-if="!$vuetify.breakpoint.mdAndUp"
                        fab
                        depressed
                        small
                        to="/newSegnalazione"
                    >
                        <v-icon small>fas fa-plus</v-icon>
                    </v-btn>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            background-color="white"
                            :items="keys"
                            label="Ordina Per"
                            class="blackTextWriting"
                        >
                            <template v-slot:prepend-inner>
                                <v-icon small>fas fa-sort</v-icon>
                            </template>
                        </v-select>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                            v-model="sortDesc"
                            mandatory
                        >
                            <v-btn
                                large
                                height="40px"
                                depressed
                                color="primary"
                                :value="false"
                            >
                                <v-icon small class="white--text">fas fa-chevron-up</v-icon>
                            </v-btn>
                            <v-btn
                                large
                                height="40px"
                                depressed
                                color="primary"
                                :value="true"
                            >
                                <v-icon small class="white--text">fas fa-chevron-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <v-btn
                            class="ml-2"
                            color="primary"
                            fab
                            depressed
                            small
                            to="/newSegnalazione"
                        >
                            <v-icon small>fas fa-plus</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item._id"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                    >
                        <v-card :to="'/schedaSegnalazione?_id='+item._id">
                            <v-card-title class="subheading font-weight-bold">
                                {{ item.titolo }}
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-list dense>
                                <v-list-item
                                    v-for="(key, index) in filteredKeys"
                                    :key="index"
                                >
                                    <v-list-item-content :class="{ 'secondary--text bold--text': sortBy === key }">
                                        {{ replaceUnderscore(capitalize(key)) }}:
                                    </v-list-item-content>
                                    <v-list-item-content
                                        class="align-end"
                                        :class="{ 'secondary--text bold--text': sortBy === key }"
                                    >
                                        <template v-if="key == 'data_creazione'">{{ formatDate(item[key.toLowerCase()]) }}</template>
                                        <template v-else><div class="trimTextTooLong">{{ item[key.toLowerCase()] }}</div></template>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row
                    class="mt-2"
                    align="center"
                    justify="center"
                >

                    <v-spacer></v-spacer>

                    <span
                        class="mr-4
            grey--text"
                    >
            Pagina {{ page }} di {{ numberOfPages }}
          </span>
                    <v-btn
                        fab
                        small
                        color="primary"
                        class="mr-1 white--text"
                        @click="formerPage"
                    >
                        <v-icon small class="white--text">fas fa-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        small
                        color="primary"
                        class="ml-1"
                        @click="nextPage"
                    >
                        <v-icon small>fas fa-chevron-right</v-icon>
                    </v-btn>
                </v-row>
            </template>
        </v-data-iterator>
    </v-container>
</template>

<script>
import { momentDate } from '@/js/formatDate';
import apiSegnalazioni from '@/js/pages/segnalazioni';
import {generalFunction} from "@/js/generalFunction";
const FiltriLista = () => import('@/components/FiltriLista');

export default {
    name: 'listSegnalazioni',
    components: {
        FiltriLista
    },
    data() {
        return {
            segnalazioni: [],
            token: this.$cookies.get('token'),
            ruolo: this.$store.state.ruolo,
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            sortBy: 'numero',
            keys: [
                'numero',
                'titolo',
                'note',
                'data_creazione',
            ],
        }
    },
    computed: {
        numberOfPages () {
            return Math.ceil(this.segnalazioni.length / this.itemsPerPage)
        },
        filteredKeys () {
            return this.keys.filter(key => key !== 'Name')
        },
    },
    async created() {
        let token = this.$cookies.get('token');
        if(token) {
            let ruolo = await generalFunction.getRuolo(token);
            if (ruolo) {
                if (ruolo === 'Cliente') {
                    this.isMaster = false;
                    this.segnalazioni = await apiSegnalazioni.getAllMine(token);
                } else if (ruolo === 'Partner') {
                    this.isMaster = true;
                    this.segnalazioni = await apiSegnalazioni.getAllPartner(token);
                } else {
                    this.keys.splice(4, 0, "nome_cliente");
                    this.isMaster = true;
                    this.segnalazioni = await apiSegnalazioni.getAll(token);
                }
            }
        }
    },
    methods: {
        capitalize(string) {
            if(string) return string.charAt(0).toUpperCase() + string.slice(1);
            else return '';
        },
        replaceUnderscore(string) {
            if(string) return string.replace(/_/g, " ");
            else return '';
        },
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
        formatDate(date) {
            return momentDate(date);
        }
    },
}
</script>
